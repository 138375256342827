/**
 * Hotfix styles for nav
 */
.nav-pills .nav-link {
    color: rgb(180, 180, 180);
}

.nav-sidebar .nav-item .nav-link {
    margin-bottom: 0.2rem;
}

.nav-sidebar .nav-item .nav-link.no-highlight {
    margin-bottom: 0rem;
}

.nav-sidebar .nav-item:last-child .nav-link.no-highlight {
    margin-bottom: 0.2rem;
}

.nav-link:hover {
    cursor: pointer;
}

.nav-item a.active p {
    font-weight: 800;
}

@include media-breakpoint-down(sm) {
    // fix wrapping glitch
    .nav-link {
        display: flex;
    }
}

/**
 * Hotfix for sidebar
 */

/* auto-sized table (260px is main-navbar, title, panel height)*/
.fixed-table {
    height: -moz-calc(100vh - 235);
    height: -webkit-calc(100vh - 235px);
    height: calc(100vh - 235px);
}

/*Change sidebar size*/
.main-sidebar {
    width: 230px;
    z-index: 1100; /*sidebar-overlay is 1099*/
    /* font-size: 0.8rem; */
}
.main-sidebar,
.main-sidebar .sidebar {
    overflow-x: hidden;
}

.sidebar-mini-icon {
    margin-left: 0;
    margin-right: 0.5rem;
    font-weight: 600;
    width: 25px !important;
    display: inline-block;
    text-align: center;
}
