@import "../css/adminlte.css";

@import "_var.scss";
@import "_functions.scss";
@import "_print.scss";
@import "_typography.scss";
@import "_navigation.scss";
@import "_react_table.scss";
@import "_form.scss";
@import "_element.scss";

body,
html {
    height: 100% !important;
    font-size: 0.9rem;
}

.bg-adminlte-gray {
    background-color: #f4f6f9;
}

.force-hidden {
    visibility: hidden;
}

.force-hidden * {
    visibility: hidden;
}

.has-danger {
    border-color: #dd4b39 !important;
    color: #dd4b39 !important;
}

/* .tooltip .tooltip-inner, .tooltip .arrow::before{
    background: #dd4b39 !important;
} */

.tooltip {
    pointer-events: none;
    z-index: 9999999;
}

.tooltip-arrow,
.tooltip > .tooltip-inner {
    background-color: #dd4b39;
}

.tooltip > .arrow::before {
    border-right-color: #dd4b39;
    border-left-color: #dd4b39;
}

/* Hotfix custom controll*/
.custom-control {
    padding-left: 0 !important;
}

.custom-control-label {
    padding-left: 1.5rem !important;
    font-weight: normal !important;
}

/**/

.wrapper {
    overflow: auto;
}

.content-wrapper {
    height: 100%;
    padding: 10px;
}

.hoverable:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.react-datepicker__input-container {
    width: 100%;
}

/* Hotfix remove Select Menu styling */
.select-hotfix div[class*="menu"] {
    margin-top: 0px !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.select-has-danger div[class*="control"] {
    border-color: #dd4b39 !important;
}

/* Hotfix moved Select Menu styling to here */
.select-hotfix .ReactVirtualized__List,
.ReactVirtualized__Grid .ReactVirtualized__List {
    top: 100%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
}

.select-hotfix div[class*="control"] {
    min-height: 2.24rem;
    /* height: 2.24rem; */
}

.select-hotfix div[class*="indicatorContainer"] {
    min-height: 2.24rem;
    height: 2.24rem;
}

.select-hotfix div[class*="-control"] > div {
    margin-top: -2px;
}

/* Creatable might have multiple lines */
.select-creatable div[class*="control"] {
    height: auto;
}

.react-datepicker-wrapper {
    width: 100%;
}

.content-header {
    padding: 5px 0 10px 5px;
}

.btn-xs {
    font-size: 0.8rem;
    padding: 0.15rem;
    margin-bottom: 3px;
    border-radius: 0;
}

.btn-address-book {
    font-size: 0.8rem;
    padding: 0.15rem;
    margin-top: -10px;
    margin-bottom: 5px;
    border-radius: 3;
}

.btn > i {
    margin-right: 5px;
}

.well {
    background-color: #e9ecef;
    border-radius: 0.3rem;
    padding: 10px;
    margin-bottom: 10px;
}

.help-block {
    display: block;
    color: orange;
    margin: -8px 0 8px 0;
    word-break: break-all;
}

.separator {
    display: block;
    height: 1px;
    background: #dee2e6;
    margin-top: 11px;
    margin-bottom: 11px;
}

.btn-block + .btn-block {
    margin-top: auto !important;
}

.center-it {
    margin-left: auto !important;
    margin-right: auto !important;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}

.section-row + .section-row {
    border-top: solid 1px rgb(218, 218, 218);
    padding-top: 10px;
    margin-top: 10px;
}

.login-box,
.register-box {
    width: 360px;
    margin: 10% auto;
}

.notification-item i {
    margin-right: 10px;
    width: 20px;
}

.notification-item .text-muted {
    float: right;
    font-size: 0.85rem;
}

.new-notification-container {
    max-height: 300px;
    overflow-y: auto;
}

/* Recharts Y-label sometimes too long*/
.recharts-surface {
    overflow: visible !important;
}

.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Grid {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactVirtualized__Table .ReactVirtualized__Table__headerRow {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    font-weight: 400;
    text-transform: none;
}

.ReactVirtualized__Table .ReactVirtualized__Table__headerColumn {
    padding: 0px 10px 0px 5px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    margin-right: 5px;
}

.table-even-row {
    background-color: rgba(0, 0, 0, 0.05);
}

.ReactVirtualized__Table__rowColumn {
    align-self: flex-start;
    margin: auto 5px;
}

.floating-callout {
    float: right;
    margin: 0 !important;
}

.report-tile {
    border: 1px solid #ddd;
    /* border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; */
    padding: 7.5px 0;
    margin: 5px;
}

.report-tile .report-btn {
    min-height: 100px;
    white-space: normal;
}

.report-tile .report-btn svg {
    max-height: 40px;
    width: 40%;
    height: auto;
}

.report-explanation {
    white-space: pre-wrap;
}

.hybrid-report-explanation {
    text-align: left;
    white-space: pre-wrap;

    .description {
        font-style: italic;
        color: gray;
    }
}

.btn-default_productStockCogsInfo {
    padding: 0;
    width: 100%;
    outline: none;
    background: none;
    border: none;
}

.btn-default_productStockCogsInfo:active {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

.modal-content_productStockCogsInfo h5 {
    font-size: 1.2rem;
}

.coa-badge {
    margin-top: 5px;
    white-space: break-spaces;
}
