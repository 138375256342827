/**
 * Styles for
 * 1. Widget finance
 * 2. Card
 * 3. Report
 */

/**
 * Styles for any widget / elements
 */

.mutationSummary {
    dt,
    dd {
        display: inline-block;
        font-size: 1.2em;
    }

    dd {
        float: right;
    }
}

.transactionPaymentMenu {
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        row-gap: 1em;

        .ReactTable {
            overflow: scroll;
        }
    }
}

/**
 * Styles for card
 * .card-outline, .card-header, .card-body
 */

.card-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &::after {
        display: none; // delete unused ::after element styles from adminLTE
    }

    @include media-breakpoint-down(sm) {
        .action {
            display: flex;
            flex-direction: row-reverse;
            min-width: 50%;
        }
    }

    @include media-breakpoint-up(md) {
        .action {
            margin-left: auto; // pull to right
        }
    }
}

/**
 * Styles for report
 */

.report-tile {
    border: 0 !important;
    padding: 0 !important;
}

.nav-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;

    .nav {
        width: max-content;
    }

    .nav-item a {
        font-size: 1.2em;
    }
}

.field-array-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
}
