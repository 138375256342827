$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$default-font-family: "Helvetica", "Arial", sans-serif;

@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway-Light.ttf");
}

@font-face {
    font-family: "RalewayLightItalic";
    src: url("../fonts/Raleway-LightItalic.ttf");
}

@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway-Medium.ttf");
}

@font-face {
    font-family: "RalewayItalic";
    src: url("../fonts/Raleway-MediumItalic.ttf");
}

@font-face {
    font-family: "RalewayBold";
    src: url("../fonts/Raleway-SemiBold.ttf");
}

@font-face {
    font-family: "RalewayBoldItalic";
    src: url("../fonts/Raleway-SemiBoldItalic.ttf");
}
