/**
 * Override styles for react table
 */

// .table-summary is summary table in form
.table-summary {
    margin-top: 15px;
}

.table-summary th {
    width: 50%;
}

// Hotfix: Fix various UI bug
// react-table header have slight skew when its child have scrolling
.fixed-table .rt-thead {
    overflow-y: scroll;
}

// remove extranous scroll-x in react-table when window width is small. BUT this will cause small part of table not visible
.fixed-table .rt-thead,
.ReactTable .rt-tbody {
    overflow-x: hidden;
}

// required to allow \n in table strings. but cause visual bug(extra newline) to Inputs inside table when using small width
.rt-td,
.rt-th {
    white-space: pre-wrap !important;
}

// so, added this as hotfix
.dynamic-table .rt-td,
.rt-th {
    white-space: normal !important;
}

.rt-td {
    overflow: visible !important;
}

.ReactTable .-pagination {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
}

.ReactTable .custom-checkbox.custom-control {
    margin-left: 8px;
}

.sub-table {
    border-bottom: 10px solid #dee2e6;
    border-left: 2px solid #dee2e6;
    border-right: 2px solid #dee2e6;
    margin-bottom: 0px;
    font-size: 0.9rem;
}

.sub-table th {
    vertical-align: top !important;
}

.sub-table td {
    border: 1px solid #dee2e6;
}

.rt-inventory-card {
    .rt-tbody .rt-td {
        font-size: 12px;
        padding: 2px;
    }
}
