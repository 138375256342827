/** 
 * All about form hot fix or styles override
 */

.form-control {
    font-size: 0.9rem;
}

.login-box .form-control {
    font-size: 1rem;
}

.dynamic-table .form-group {
    margin: 0;
}

.ReactVirtualized__Table .form-group {
    margin-bottom: 0;
}

/**
 * Form input hotfix
 */

/*Hotfix whiter placeholder*/
input::-webkit-input-placeholder {
    color: rgb(170, 170, 170) !important;
}

input::-moz-placeholder {
    color: rgb(170, 170, 170) !important;
}

input:-ms-input-placeholder {
    color: rgb(170, 170, 170) !important;
}

input::placeholder {
    color: rgb(170, 170, 170) !important;
}

textarea::placeholder {
    color: rgb(170, 170, 170) !important;
}

textarea::-webkit-input-placeholder {
    color: rgb(170, 170, 170) !important;
}

textarea::-moz-placeholder {
    color: rgb(170, 170, 170) !important;
}

textarea:-ms-input-placeholder {
    color: rgb(170, 170, 170) !important;
}

.select-hotfix div[class*="placeholder"] {
    color: rgb(170, 170, 170) !important;
}
/*hotfix whiter done*/

.select-disabled div[class*="-control"] {
    border: 1px solid #ced4da !important;
    background-color: #e9ecef !important;
    color: black !important;
}

.select-disabled div[class*="-singleValue"] {
    color: rgb(90, 90, 90) !important;
}

// Fix supplierId, whId, supplierDetail order on responsive
@include media-breakpoint-down(sm) {
    #supplierId,
    #customerId,
    #purchaseSummary,
    #salesSummary,
    #cmSummary {
        order: 1;
    }

    #supplierDetail,
    #customerDetail,
    #purchaseAddInfo,
    #salesAddInfo,
    #cmAddInfo {
        order: 2;
    }

    #warehouseId {
        order: 3;
    }
}
