body,
* {
    font-family: $default-font-family;
}

.brand-text {
    font-family: "Raleway";
}

h2,
h3,
h4,
.h2,
.h3,
.h4 {
    font-size: 1.2em !important;
    margin-top: 10px;
}
