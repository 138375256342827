/** Print CSS */
// Print layout wrapper
#print-area {
    padding: 20px;

    .table thead th {
        vertical-align: top;
    }

    @include media-breakpoint-down(sm) {
        overflow: scroll;
    }
}

// Gross net table detail print
.gross-net-detail {
    td:nth-child(3) {
        width: 10px !important;
    }

    td {
        font-size: 10px;
    }
}

// General parent table print
.ra-table-print {
    .docTitle {
        font-size: 28pt;
        font-weight: bold;
        padding: 15px;
    }

    > thead {
        border-bottom: 1cm solid transparent;
    }

    > tbody > tr > td {
        border-bottom: none !important;
        padding: 0 15px;
    }

    // all child table
    .table {
        margin-bottom: 0;
    }
}

// General table detail print
.ra-table-detail-print {
    @include media-breakpoint-down(sm) {
        width: map-get($breakpoints, md);
    }
}

.ra-table-print,
.gross-net-report {
    margin-bottom: 0;
    white-space: pre-line;
    width: 100%;

    thead th {
        padding: 0px 15px;
        border: none;
        font-weight: normal;
    }

    tr:last-child th {
        height: 50px;
        vertical-align: top;
    }

    tbody td {
        border: none;
    }
}

.ra-table-detail-print,
.gross-net-detail {
    table-layout: fixed;

    .rowNo {
        width: 50px;
    }

    th {
        background-color: #ccc;
        font-weight: bold !important;
        height: auto !important;
    }

    td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    td.description {
        white-space: pre-wrap;
    }
}

.gross-net-detail,
.ra-table-detail-print,
.ra-summary-table {
    td,
    th {
        border: 1px solid #eeeeee !important;
        padding: 5px !important;
    }

    td.qty {
        padding: 0 !important;
    }

    td.rowNo,
    th.rowNo {
        width: 3% !important;
        text-align: center;
    }

    th.subTotal,
    td.subTotal {
        width: 20% !important;
    }

    td.qty,
    th.qty,
    td.disc,
    th.disc {
        width: 15% !important;
    }

    td.price,
    th.price {
        width: 12% !important;
    }

    th.plpercentage {
        width: 5% !important;
    }

    td.subTotal,
    td.summaryTable {
        text-align: right;
    }
}

/* adminlte has page-break-inside to always */
/* will cause bug print on firefox */
tr {
    page-break-inside: unset;
}

.printQtyTable {
    width: 100%;
    table-layout: fixed;
}

.printQtyTable td:not(:last-child) {
    border-right: 1px solid #eeeeee !important;
}

.ra-footer-table,
.ra-summary-table {
    width: 100%;

    > tr > td {
        border-top: none !important;
    }
}

.ra-footer-table {
    td {
        border: 1px solid #f2f2f2 !important;
    }
}

.ra-summary-table {
    td {
        padding: 5px;
    }
}

#gross-net-report {
    width: 100%;
}

td.subTotal {
    width: 20%;
}

// .table-param-info is table used in HTMLPrintHeader (print preview header)
.table-param-info {
    /* width: 250px; */
    margin: 0 0 auto auto;
    border: 1px solid #eeeeee;

    td {
        border: none !important;
        padding: 0 0.3rem;
    }
}

@media screen {
    .table-responsive {
        max-height: 600px;
    }

    .ra-table-detail-print {
        &.overflow {
            width: auto;
            // max-width: auto;
            min-width: 2000px;
        }
    }
}

@media print {
    html,
    body,
    .wrapper {
        overflow-x: visible !important;
        font-size: 1rem;
    }

    .docTitle * {
        font-size: 18pt;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    img.logo {
        height: 150px;
    }

    #print-menu {
        position: absolute;
        height: 0px;
        visibility: hidden;
    }

    #print-area {
        padding: 0;
        border: none;
        margin: 0;
    }

    @page {
        margin: 0.5cm 1cm;
    }

    th,
    td,
    p,
    span {
        font-size: 14px;
    }

    .ra-table-detail-print th,
    .gross-net-detail th {
        text-transform: uppercase;
    }

    .ra-table-detail-print td,
    .ra-table-detail-print th,
    .ra-summary-table td,
    .gross-net-detail td,
    .gross-net-detail th,
    .ra-summary-table td,
    .ra-footer-table td {
        border: 1px solid black !important;
        font-size: 12px;
    }

    .printQtyTable td {
        border: none !important;
    }

    .printQtyTable td:not(:last-child) {
        border-right: 1px solid black !important;
    }

    .gross-net-detail th.customer,
    .gross-net-detail th.docNo,
    .gross-net-detail th.product,
    .ra-table-detail-print th.customer,
    .ra-table-detail-print th.docNo,
    .ra-table-detail-print th.product,
    td.product {
        width: 12% !important;
        word-wrap: break-word;
    }

    .subtitle {
        margin-top: 0.5cm;
    }

    .ra-table-detail-print h4 {
        margin: 0.5rem 0;
    }
}

/**
 * Default print layout
 *
 */

.ra-report-pivot {
    > thead tr th:first-child {
        width: 30%;
    }

    tr th {
        background-color: #ccc;
    }

    tr td {
        border: 1px solid #ccc;
    }

    td.ra-report-wrapper {
        padding: 0;
        border: none;
    }

    @media print {
        tr td,
        tr th {
            border: 1px solid #000;
        }
    }
}

.ra-report {
    padding: 0;
    margin-bottom: 0;

    tr td:last-child {
        width: 1%;
        white-space: nowrap;
    }
}

.ra-report-meta-print {
    td,
    th {
        border: none;
        padding: 0;
        font-size: 14pt;
    }

    tr:last-child th,
    tr:last-child td {
        padding-bottom: 0.5em !important;
        border-bottom: 1px solid black;
    }
}
